<template>
  <div>
    <div class="banner">
      <div class="container" v-if="screenWidth > 992">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>{{$t("header.about")}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{$t("header.contactUs")}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container contact">
        <div>
          <img src="../../assets/image/about-us/WhatsApp.png" alt="" srcset="">
          <p>WhatsApp</p>
        </div>
        <div>
          <img src="../../assets/image/about-us/WeChat.png" alt="" srcset="">
          <p>WeChat</p>
        </div>
        <div>
          <img src="../../assets/image/about-us/mailto.png" alt="" srcset="">
          <p>support@unitycapfx.com</p>
        </div>
      </div>
    </div>

    <div class="full-slider">
      <div class="logo"></div>
      <p>{{$t("aboutUs.whoAreWe.describe")}}</p>
    </div>
    <div class="container map-container">
      <h4>{{$t("aboutUs.whoAreWe.title2")}}</h4>
      <div class="map">
        <i :class="{'map__zero-locale': screenWidth <992}"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'who-are-we',
  computed: {
    screenWidth() {
      let width = localStorage.getItem('screen_width') || 1200
      return width
    },
  }
}
</script>

<style scoped lang="scss">
.banner {
  padding-bottom: 100px;
  [class^='banner'] {
    text-align: center;
  }
  &__title {
    color: #000;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    height: 50px;
    line-height: 50px;

    &::after {
      content: '';
      display: inline-block;
      width: 40px;
      height: 6px;
      background: #0D73D6;
      position: absolute;
      left: 50%;
      bottom: -2px;
      transform: translate3d(-50%, 0, 0);
    }
  }
  &__sub-title {
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #00000086;
    line-height: 22px;
    margin-top: 22px;
  }
  .contact {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #000;
    margin-top: 100px;
    img {
      width: 200px;
      margin: 0 20px;
    }
  }
}

.full-slider {
  height: 360px;
  background-size: cover;
  display: flow-root;
  @include bg-image('../../assets/image/about-us/who-slider');
  .logo {
    margin: 84px auto 40px;
    width: 162px;
    height: 72px;
    background-size: cover;
    @include bg-image('../../assets/image/logo/FT');
  }
  p {
    width: 800px;
    height: 90px;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 30px;
    text-shadow: 0 1px 2px #0155A6;
    text-align: center;
    margin: 0 auto;
  }
}
.map-container {
  h4 {
    height: 50px;
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    line-height: 50px;
    text-align: center;
    margin: 80px 0 38px 0;
  }

  .map {
    width: 800px;
    height: 373px;
    margin: 0 auto 80px;
    @include bg-image('../../assets/image/about-us/who-map');
    position: relative;
    i{
      position: absolute;
      right: 110px;
      bottom: 70px;
      width: 10px;
      height: 10px;
      background-color: #0D73D6;
      border-radius: 50%;
    }
    .map__zero-locale{
      width: 5px;
      height: 5px;
      right: 10vw;
      bottom: 35px;
    }
  }
}

@media screen  and(max-width: 992px) {
  .container{
    width: 100%;
  }
  .banner{
    padding-bottom: 0px;
  }
  .banner__title{
    font-size: 26px;
  }
  .banner__sub-title{
    margin-top: 10px;
  }
  .banner__pics{
    width: 90vw;
    margin: 0 auto;
  }
  .full-slider{
    width: 100vw;
    height: auto;
    overflow: hidden;
    background-position: center center;
    padding: 20px;
    .logo{
      margin: 20px auto;
      width: 80px;
      height: 35px;
    }
    p{
      width: 100%;
      height: auto;
      font-size: 13px;
    }
  }
  .map-container{
    width: 100vw;
    h4{
      min-height: 40px;
      height: 30px;
      font-size: 26px;
      color: #000;
      line-height: 30px;
      margin: 0;
      text-align: center;
      margin-top: 20px;
    }
    .map{
      width: 90%;
      height: 170px;
      background-size: cover;
      background-position: center center;
    }
  }
}
</style>